import React, {useEffect} from "react"
import {graphql, Link} from "gatsby"
import {document, window} from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import Menu from "../components/services/menuServices"
import FirstSectionYoga from "../components/yoga/firstSection"
import SecondSectionConsulting from "../components/consulting/secondSection"

export const query = graphql`
query {
  sanityConsulting {
    subtitle
    phrase
    text
    text2
    image {
      asset {
        fluid(maxWidth: 2240) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    secondImage {
      asset {
        fluid(maxWidth: 1080) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
  }
}
`

const Consulting = ({data}) => {
  const {subtitle, phrase, text, text2, image, secondImage} = data.sanityConsulting

  useEffect(() => {
    window.onscroll = () => {
      Array.prototype.forEach.call(document.querySelectorAll(".yoga--first-section"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(0, ${5 * t}%, 0) scale(1)`
        }
      }))
      Array.prototype.forEach.call(document.querySelectorAll(".consulting--second-section"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(45px, -${5 * t}%, 0) scale(1)`
        }
      }))
    }
  })

  return(
    <Layout>
      <SEO title="Consulting"/>
      <div className="language"><Link className="clickable" to="/consulting">ENG</Link> / <Link className="clickable" to="/consultoria">ESP</Link></div>

      <HeaderSection title="Consulting" description={subtitle} maxWidth="604px"/>

      <FirstSectionYoga phrase={phrase} image={image} classes="consulting"/>

      <SecondSectionConsulting text={text} text2={text2} secondImage={secondImage} />

      <Menu/>

    </Layout>
  )
}

export default Consulting
