import React from "react"
import Image from "../image"

import Arrow from "../../assets/svg/ubuntu-arrow-form.svg"

const SecondSectionConsulting = ({text, secondImage, text2, es}) => (
  <div className="consulting--second-section grid-container-13">
    <div className="text-container">
      <p>{text}</p>
      <p>{text2}</p>
      {!!es ? (
        <React.Fragment>
          <h3 data-sal="slide-down" data-sal-delay="150" data-sal-duration="800" data-sal-easing="ease">Contáctanos</h3>
          <p>Nos encantaría compartir este proceso de transformación contigo y tu proyecto</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3 data-sal="slide-down" data-sal-delay="150" data-sal-duration="800" data-sal-easing="ease">Reach out</h3>
          <p>We’d love to share this process of transformation with you</p>
        </React.Fragment>
      )}
      <form name="consulting-ubuntu" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="consulting-ubuntu"/>
        <input type="email" placeholder="Email"/>
        <button>Send <Arrow className="clickable"/></button>
      </form>
    </div>
    <Image fluid={secondImage.asset.fluid}/>

  </div>
)

export default SecondSectionConsulting
