import React from "react"
import {Link} from "gatsby"

const Menu = () => (
  <div className="services--menu">
    <p>EXPLORE</p>
    <nav>
      <Link to="/yoga" className="clickable">Yoga</Link>
      <Link to="/experiences" className="clickable">Experiences</Link>
      <Link to="/kaleidoscope" className="clickable">Kaleidoscope</Link>
      <Link to="/consulting" className="clickable">Consulting</Link>
    </nav>
  </div>
)

export default Menu
