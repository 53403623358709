import React from "react"
import Image from "../image"

const FirstSectionYoga = ({phrase, image, classes}) => (
  <div className={`yoga--first-section ${classes}`}>
    <Image fluid={image.asset.fluid} alt="Experience Ubuntu Yoga"/>
    <div className="yoga-background grid-container-13">
      <p>{phrase}</p>
    </div>
  </div>
)

export default FirstSectionYoga
